import { Link, LinkProps, useLocation, useNavigation } from "@remix-run/react";
import { User } from "@workos-inc/node";
import { LoaderCircle, Menu, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/ui/button";

interface HeaderProps {
  user: User | null;
}

interface NavItem extends LinkProps {
  title: string;
  variant: "default" | "secondary" | "ghost";
}

const Header = ({ user }: HeaderProps) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigation = useNavigation();

  useEffect(() => {
    setMenuOpen(false);
  }, [setMenuOpen, location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const items: NavItem[] = [
    {
      title: t("nav.features"),
      to: "/p/#features",
      variant: "ghost",
      prefetch: "intent",
    },
    {
      title: t("nav.howItWorks"),
      to: "/p/#howitworks",
      variant: "ghost",
      prefetch: "intent",
    },
    // {
    //   title: t("nav.pricing"),
    //   url: "/p/pricing",
    //   variant: "ghost",
    //   prefetch: "intent",
    // },
    {
      title: t("nav.contact"),
      to: "/p/contact",
      variant: "ghost",
      prefetch: "intent",
    },
    {
      title: t("nav.worksheet_gallery"),
      to: "/p/gallery",
      variant: "secondary",
      prefetch: "intent",
    },
  ];

  if (user) {
    items.push({
      title: t("nav.goToApp"),
      to: "/app",
      variant: "default",
      prefetch: "intent",
    });
  } else {
    items.push(
      {
        title: t("nav.signIn"),
        to: "/auth/signin",
        variant: "ghost",
      },
      {
        title: t("nav.signUp"),
        to: "/auth/signup",
        variant: "default",
      }
    );
  }

  const links = items.map((item) => (
    <Button asChild key={item.title} variant={item.variant}>
      <Link
        to={item.to}
        className="text-gray-700"
        {...(item.prefetch ? { prefetch: item.prefetch } : {})}
      >
        {navigation.state === "loading" &&
          navigation.location.pathname === item.to && (
            <LoaderCircle className="animate-spin" />
          )}
        {item.title}
      </Link>
    </Button>
  ));

  return (
    <header className="flex justify-between items-center py-4 px-6 bg-white shadow-sm lg:px-8">
      <div className="flex items-center">
        <h1 className="text-2xl font-semibold text-gray-900">
          <Link to="/" className="flex items-center">
            <img src="/logo.svg" alt="Assessain Logo" className="h-8 mr-4" />
            Assess<i>ai</i>n
          </Link>
        </h1>
      </div>

      {/* Mobile Menu Button */}
      <button className="lg:hidden block text-gray-700" onClick={toggleMenu}>
        {menuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      {/* Navigation - Hidden on Mobile, Visible on Desktop */}
      <nav className="hidden lg:flex space-x-6">{links}</nav>

      {/* Mobile Navigation - Visible when the menu is open */}
      {menuOpen && (
        <nav className="absolute top-16 left-0 w-full bg-white border-t border-gray-200 lg:hidden">
          <ul className="flex flex-col items-center space-y-4 py-4">{links}</ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
