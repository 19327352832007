import { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useLoaderData } from "@remix-run/react";
import { authkitLoader } from "@workos-inc/authkit-remix";
import Footer from "~/components/layout/Footer";
import Header from "~/components/layout/Header";
import { getApiService } from "~/services/api";

export const loader = async (args: LoaderFunctionArgs) => {
  return authkitLoader(args, async () => {
    const api = getApiService();
    const subjects = await api.appApiWorksheetsGetSubjectsList();
    return { subjects };
  });
};

export default function P() {
  const { user, subjects } = useLoaderData<typeof loader>();

  return (
    <div className="flex flex-col min-h-screen">
      <Header user={user} />
      <main className="flex-grow">
        <Outlet />
      </main>
      <Footer subjects={subjects} />
    </div>
  );
}
